import https from "./https";
import store from "@/store";

const staffApi = {
  getBranches(params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/branches`, {
      params
    });
  },

  async getStaffs(branchId, params) {
    const organization = store.state.liffGeneral.orgCode
    let response = await https.get(`${organization}/liff/branches/${branchId}/staffs`, { params })
    return response.data.data
  },

  async getStaff(staffId, params) {
    let response = await https.get(`general/staffs/${staffId}`, { params })
    return response.data.data
  },

  async getStaffBranchHistory(staffId, params) {
    let response = await https.get(`general/staffs/${staffId}/branch-history`, { params })
    return response.data.data
  },

  async customerBindStaff(params) {
    const organization = store.state.liffGeneral.orgCode
    let response = await https.post(`${organization}/liff/sales/customer-bind-staff-v2`, params)
    return response.data
  },

  async sendBindTemplate(params) {
    const organization = store.state.liffGeneral.orgCode
    let response = await https.post(`${organization}/liff/sales/send-bind-template`, params)
    return response.data
  },

  async checkBinding(staffId = null) {
    const organization = store.state.liffGeneral.orgCode
    if (staffId) {
      let response = await https.get(`${organization}/liff/sales/check-binding?staff_id=${staffId}`)
      return response.data
    } else {
      let response = await https.get(`${organization}/liff/sales/check-binding`)
      return response.data
    }
  }
};

export default staffApi
